import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  officesPopularsHeaderUrl,
  officesPopularsUrl,
  imgOfficeUrl,
  mPackageUrl,
  imgPackageUrl,
  buyPackageUrl,
  bankUrl,
} from "../utils/baseUrl";
import { ShopCard, TopHeader } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import Moment from "react-moment";

const SaveBankTransfer = () => {
  // const { id } = useParams();
  //"/savebankpayment/:orderID/:methodID"
  //bankCompanyUrl,imgBankUrl,bankUrl
  const { orderID, methodID } = useParams();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [dataBank, setDataBank] = useState([]);
  const [orderData, setOrderData] = useState({});
  const [selectedFile, setSectedFile] = useState(null);
  // locations id,thName,enName,photo,description,zipCode,latitude,longitude,comments,created_at

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bankName: "",
      bankNo: "",
      paidTotal: "",
      description: "",
    },
  });
  /* order_paid id,paidDate,orderID,companyID,orderID,memberID,partnerID,distributeID,packageId,packageName,packagePrice,
slipImg,confirmDate,paidTotal,paidStatus,created_at,bankID,bankName,description */
  function readBank() {
    axios.get(`${bankUrl}readbankname.php`).then(function (response) {
      // console.log(response.data);
      setDataBank(response.data);
    });
  }
  function readOrder(id) {
    axios
      .get(`${buyPackageUrl}readorderbyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setOrderData(response.data);
      });
  }
  useEffect(() => {
    var id = localStorage.getItem("id");
    setUserId(id);
    readBank();
    readOrder(orderID);
  }, []);

  useEffect(() => {
    if (orderData) {
      // setValue([{ paidTotal: orderData.packagePrice }]);
      setValue("paidTotal", orderData.packagePrice);
    }
  }, [orderData]);

  const handleOnChange = (e) => {
    console.log("----------");
    console.log(e.target.files[0]);
    console.log("----------");
    setSectedFile(e.target.files[0]);
  };

  const handleOnSubmit = (data) => {
    setLoading(true);
    //orderID, methodID
    const formData = new FormData();
    formData.append("fileData", selectedFile);
    formData.append("companyID", orderData.companyID);
    formData.append("memberID", userId);
    formData.append("distributeID", orderData.distributeID);
    formData.append("packageId", orderData.packageId);
    formData.append("bankID", data.bankNo);
    formData.append("paidTotal", data.paidTotal);
    formData.append("orderID", orderID);
    formData.append("methodID", methodID);
    formData.append("description", data.description);
    axios
      .post(`${buyPackageUrl}savepaid.php`, formData)
      .then(function (response) {
        console.log(response.data);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        reset();

        setLoading(false); // Stop loading
      });
  };
  console.log("Order: ", orderData);
  return (
    <>
      <TopHeader text="Payment Bank Transfer" subtext="Giftlikes Provider" />
      <section className="section">
        <div className="container mx-auto">
          {/* {orderID} {methodID} */}
          <div className="p-2 border">
            {/* packageId,packageName,packagePrice */}
            <h2>
              <span className="font-bold">Package Name:</span>{" "}
              {orderData.packageName}
            </h2>
            <h2>
              <span className="font-bold">Package Price:</span>{" "}
              {orderData.packagePrice}
            </h2>
          </div>
          <div>
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
                  Select Bank
                </label>
                <select
                  {...register("bankNo", {
                    required: true,
                  })}
                  className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                >
                  <option value=""></option>
                  {dataBank.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.thName}
                    </option>
                  ))}
                </select>
                {errors.bankNo && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    Enter bank.
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
                  Total
                </label>
                <input
                  type="text"
                  className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                  placeholder="Total"
                  name="paidTotal"
                  {...register("paidTotal", {
                    required: true,
                    // maxLength: 10,
                  })}
                />
                {errors.paidTotal && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    Enter Total
                  </div>
                )}
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
                  Description
                </label>
                <input
                  type="text"
                  className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                  placeholder="Description"
                  name="description"
                  {...register("description", {
                    required: true,
                    // maxLength: 10,
                  })}
                />
                {errors.description && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    Enter description
                  </div>
                )}
              </div>
              {/*               <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700  my-2 ml-1">
                  สาขา
                </label>
                <input
                  type="text"
                  className="w-full py-2 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow dark:bg-gray-600 dark:text-gray-100"
                  placeholder="สาขา"
                  name="branchName"
                  {...register("branchName", {
                    required: true,
                  })}
                />
                {errors.branchName && (
                  <div className="my-3 text-normal text-red-500 text-xs italic">
                    ป้อนสาขา
                  </div>
                )}
              </div> */}

              <div className="my-2">
                <label>Slip Photo</label>
                <div className="flex flex-col items-start">
                  <input type="file" onChange={handleOnChange} />
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  disabled={loading}
                  type="submit"
                  className="mr-1 bg-lime-500 hover:bg-lime-700 text-white text-center py-1 px-4 rounded"
                >
                  {loading ? <>Please wait..</> : <>Save</>}
                </button>
              </div>
            </form>
          </div>
        </div>
        {/*     <div className="container mx-auto">
{orderData.map((item, i) => (
<div
  key={i}
  className="flex justify-between items-center py-4 px-2 border"
>
  <div className="flex gap-4 ">
    <div>
      <span className="font-bold">
        {item.id}#
        <Moment format="DD/MM/YYYY" date={item.orderDate} />
      </span>
      <br />
      <span className="font-bold">Name:</span> {item.packageName}
      <br />
      <span className="font-bold">Price:</span> {item.packagePrice}
    </div>
  </div>
  <div>
    <Link
      to={`/paymentmethod/${item.id}`}
      className="mr-1 bg-lime-500 hover:bg-lime-700 text-white text-center py-1 px-4 rounded"
    >
      Payment
    </Link>
  </div>
</div>
))}
</div> */}
      </section>
    </>
  );
};

export default SaveBankTransfer;
