import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logo.png";
import NavLinks from "./NavLinks";
import { NavLink } from "react-router-dom";
import { gfcompanyUrl, imgLogoUrl } from "../../utils/baseUrl";
import axios from "axios";
const Nav = () => {
  const [open, setOpen] = useState(false);
  const [dataApp, setDataApp] = useState({});

  function readAppData() {
    axios.get(`${gfcompanyUrl}readlogo.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }

  useEffect(() => {
    readAppData();
  }, []);
  return (
    <nav className="flex justify-between mt-4 lg:mt-0">
      {Object.keys(dataApp).length === 0 && dataApp.constructor === Object ? (
        <NavLink to={"/"}>
          <img src={Logo} alt="logo" className="h-9 cursor-pointer" />
        </NavLink>
      ) : (
        <>
          <NavLink to={"/"}>
            <img
              src={`${imgLogoUrl}${dataApp?.companyLogo}`}
              className="h-9 cursor-pointer"
            />
          </NavLink>
          {/* <span>{dataApp?.companyLogText}</span> */}
        </>
      )}
      <ul className="md:flex hidden items-center gap-10">
        <NavLinks open={open} setOpen={setOpen} />
      </ul>
      {/* Mobile Nav */}
      <ul
        className={`fixed top-0 z-50 bg-white w-2/3 h-screen shadow-2xl
  md:hidden flex flex-col gap-10 text-medium  p-7 pt-20 duration-500
  ${open ? "right-0" : "right-[-100%]"}`}
      >
        <NavLinks open={open} setOpen={setOpen} />
      </ul>

      <div className="text-2xl md:hidden z-50" onClick={() => setOpen(!open)}>
        <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
      </div>
    </nav>
  );
};

export default Nav;
