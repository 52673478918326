import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  imglocationUrl,
  officesHerosUrl,
  officesCompanyUrl,
  imgOfficeUrl,
} from "../utils/baseUrl";

import axios from "axios";
import {
  Blog,
  Download,
  Feature,
  Companies,
  SliderPackage,
  TopHero,
} from "../components";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState([]);

  function readAppData(flag) {
    axios
      .get(`${officesCompanyUrl}readpagebyflags.php/${flag}`)
      .then(function (response) {
        console.log(response.data);
        setDataApp(response.data);
      });
  }

  useEffect(() => {
    readAppData("CP");
  }, []);
  return (
    <section className="">
      <div className="container mx-auto">
        <TopHero />
      </div>
      {/*       <div>
        <SliderPackage flag={"PH"} owner={"User"} />
      </div> */}
      <div className="container mx-auto mt-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {dataApp.map((item, i) => {
          // let side = i % 2 != 0 ? true : false;

          return <Blog content={item} key={i} />;
        })}
      </div>
    </section>
  );
};

export default Contact;
