import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  imglocationUrl,
  officesHerosUrl,
  officesDwProviderUrl,
  imgOfficeUrl,
} from "../utils/baseUrl";
import axios from "axios";

import DownloadAds from "./DownloadAds";
import appStore from "../assets/icons/buttons/app-store.svg";
import googlPlay from "../assets/icons/buttons/google-play.svg";
const Download = () => {
  const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState([]);

  function readAppData() {
    axios.get(`${officesDwProviderUrl}readlimit.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }

  useEffect(() => {
    readAppData();
  }, []);

  return (
    <section className="section text-center bg-[#020917] pb-[8rem]">
      <div className="container mx-auto">
        <p className="text-primary text-sm">{dataApp?.title}</p>
        <h1 className="md:text-4xl text-2xl text-white mt-1 font-semibold">
          {dataApp?.subtitle}
        </h1>
        <div className="flex justify-center">
          <p className="text-white text-center w-full md:w-2/3 md:py-4 py-2 leading-relaxed">
            {dataApp?.description}
          </p>
        </div>

        {/* dowload ads */}
        <div className="flex justify-center">
          <DownloadAds />
        </div>
        {/*         <div className="flex gap-x-3 justify-center xl:justify-start">
          <button className="btn-provider">
            <img src={googlPlay} className="w-[132px] h-[36px]" alt="" />
          </button>
          <button className="btn-cta">
            <img src={appStore} className="w-[132px] h-[36px]" alt="" />
          </button>
        </div> */}
      </div>
    </section>
  );
};

export default Download;
