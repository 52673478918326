import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

import {
  officesPopularsHeaderUrl,
  officesPopularsUrl,
  imgOfficeUrl,
  mPackageUrl,
  imgPackageUrl,
  buyPackageUrl,
} from "../utils/baseUrl";

import axios from "axios";
import Swal from "sweetalert2";

const ShopCard = ({ companydata, pkId, memberID, distributeID }) => {
  const [loading, setLoading] = useState(false);
  const [packageData, setPackageData] = useState({});
  function readPackage(id) {
    axios
      .get(`${buyPackageUrl}readpackagebyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setPackageData(response.data);
      });
  }

  useEffect(() => {
    readPackage(pkId);
  }, []);
  const onSaveCompany = (pkID, memberID, companyID) => {
    setLoading(true);
    axios
      .post(`${buyPackageUrl}savepackage.php`, {
        memberID: memberID,
        packageId: pkID,
        companyID: companyID,
        distributeID: distributeID,
      })
      .then(function (response) {
        console.log(response.data);
        // toast.success("Successfully created!");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <div className="w-full flex flex-col gap-y-[30px] lg:flex-row lg:gap-x-[30px]">
      {companydata?.length > 0 ? (
        <>
          <div className="w-full grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {companydata?.map((item, i) => {
              return (
                <div key={i}>
                  <div className="bg-white shadow-2xl min-h-[350px]">
                    <div className="mb-6 flex justify-center">
                      {item?.companyPhoto !== null ? (
                        <>
                          {/*                           <img
                            src={`${imgShopUrl}${item?.companyPhoto}`}
                            className="object-cover h-48 w-96"
                          /> */}
                        </>
                      ) : null}
                    </div>
                    <div className="mb-8 text-[30px] font-medium text-center">
                      <h2 className="mb-3">
                        {/* ({item?.id})#{item?.companyName} */}
                        {item?.companyName}
                      </h2>
                      <p className="text-base my-4">
                        {packageData?.shortdetail}
                      </p>
                      {/*                       <p className="text-gray-600 text-sm leading-normal my-2">
                        {packageData?.longdetail}
                      </p> */}
                      <p className="text-xl mb-4">
                        <span className="text-rose-600 ">
                          {Number(packageData?.price).toFixed(0)}฿
                        </span>
                        /{packageData?.period}-
                        <span className="text-base">{packageData?.unit}</span>
                      </p>
                      <p>
                        <button
                          onClick={() =>
                            onSaveCompany(pkId, memberID, item?.id)
                          }
                          className="btn-outline px-8 py-2"
                        >
                          Buy Package
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ShopCard;
