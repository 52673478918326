import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  imglocationUrl,
  officesHerosUrl,
  officesCompanyUrl,
  imgOfficeUrl,
} from "../utils/baseUrl";

import axios from "axios";

import {
  Blog,
  Download,
  Feature,
  Companies,
  BlogFull,
  Faq,
  SliderPackage,
  TopHero,
} from "../components";
/* import img1 from "../assets/img/d1.png";
import img2 from "../assets/img/img2_1.jpg";
import img3 from "../assets/img/img3_1.jpg"; */
const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState(null);
  // const params = new URLSearchParams(window.location.pathname);
  function readAppData(flag) {
    axios
      .get(`${officesCompanyUrl}readpagebyflag.php/${flag}`)
      .then(function (response) {
        console.log(response.data);
        setDataApp(response.data);
      });
  }

  /*   useEffect(() => {
    readAppData("HP");
  }, []); */

  useEffect(
    useCallback(() => {
      readAppData("HP");
      return () => {};
    }, [])
  );
  /*   const contents = [
    {
      title: "Safe, Affordable, And Trusted",
      des: "Volunteer trail stewardship projects in America’s parks and forests designed specifically for college student groups and young professionals.",
      img: img1,
    },
    {
      title: "Enjoy Your Life With Us Now!",
      des: "Volunteer trail stewardship projects in America’s parks and forests designed specifically for college student groups and young professionals.",
      img: img2,
    },
    {
      title: "Let’s Enjoy Nature With Us",
      des: "Volunteer trail stewardship projects in America’s parks and forests designed specifically for college student groups and young professionals.",
      img: img3,
    },
  ]; */
  return (
    <>
      <div className="container mx-auto">
        <TopHero />
      </div>

      <Feature />
      <div className="container mx-auto mt-6 mb-8">
        <BlogFull content={dataApp} />
      </div>

      <Download />
      {/* <SliderPackage flag={"PH"} owner={"User"} /> */}
      {/* <Companies /> Provider*/}
      <div>
        <Faq />
      </div>
    </>
  );
};

export default Dashboard;
