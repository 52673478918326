import React, { useState, useEffect } from "react";
import { officesMenusUrl } from "../utils/baseUrl";
import axios from "axios";
import SocialMedia from "./SocialMedia";
import Logo from "../assets/img/logo.png";
import { NavLink } from "react-router-dom";
const Footer = () => {
  const [loading, setLoading] = useState(false);
  const [Links, setLinks] = useState([]);

  function readAppData() {
    axios.get(`${officesMenusUrl}read.php`).then(function (response) {
      console.log(response.data);
      setLinks(response.data);
    });
  }

  useEffect(() => {
    readAppData();
  }, []);

  console.log(Links);
  /*   const Links = [
    { title: "Location", links: ["America", "Asia", "Eurpoe", "Africa"] },
    { title: "Contact", links: ["About Me", "Teams", "Profile", "FAQ"] },
    { title: "Legals", links: ["Privacy", "Disclaimer", "Terms", "Company"] },
  ]; */
  return (
    <footer className="py-[4rem] w-full bg-[#081730] text-white">
      <div className="container mx-auto  flex flex-col items-center justify-between md:flex-row">
        <div className="flex gap-4 justify-center">
          {Links.slice(3, 6).map((link, i) => (
            <NavLink
              to={link.url}
              key={i}
              className="font-semibold hover:text-primary"
            >
              {link.title}
            </NavLink>
          ))}
        </div>

        <div className="text-sm mt-4 md:mt-0">
          Copyright © 2023. All Right Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
