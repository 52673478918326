import React from "react";
import ButtonFill from "./ButtonFill";
import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  imglocationUrl,
  officesHerosUrl,
  officesCompanyUrl,
  imgOfficeUrl,
} from "../utils/baseUrl";

const BlogFull = ({ content }) => {
  console.log(content);
  //src={`${imgOfficeUrl}${content?.photo}`}
  const pic = `${imgOfficeUrl}${content?.photo}`;
  //aspect-[16/10] md:aspect-auto object-cover
  return (
    <section className="flex flex-col justify-center items-center xl:justify-start xl:flex-row xl:items-start xl:mb-8">
      {/* {content?.imageposition == "Left" ? () : null}*/}

      {content?.photo !== "" ? (
        <div className="flex-1 mt-4 rounded-xl">
          <img
            src={`${imgOfficeUrl}${content?.photo}`}
            className="place-self-start object-contain rounded-xl opacity-90"
          />
        </div>
      ) : null}

      <div className="flex-1 xl:ml-2 w-full pt-4 border-b-1 px-1 shadow-sm ">
        <h1 className="font-semibold md:leading-snug text-3xl leading-normal md:text-5xl ">
          {content?.title}
        </h1>
        <h2 className="">{content?.subtitle}</h2>
        <p className="text-sm text-gray-600 leading-relaxed py-5">
          {content?.description}
        </p>
      </div>
    </section>
  );
};

export default BlogFull;
