import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { buyPackageUrl } from "../utils/baseUrl";
import axios from "axios";
//  buyPackageUrl,
//854589589229319
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
// import SocialLogin from "react-social-login";
//createsoft.media@gmail.com
//giftlikes app
//https://jdtextileandconsolidator.me/
//http://localhost:3000/signin/3/Provider
//Client ID
//18394294506-luj6vo6p27l0fs675csatq2h5pu5boec.apps.googleusercontent.com
//Client secret
//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

//FB:1692785154563581
const SignIn = () => {
  const { id, owner } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [user, setUser] = useState({ email: "", password: "" });
  const [provider, setProvider] = useState("");
  // const [profile, setProfile] = useState(null);
  const [profile, setProfile] = useState(null);
  const [profileFb, setProfileFb] = useState(null);
  const [socialData, setSocialData] = useState(null);
  const tbname = owner === "Provider" ? "loginprovider.php" : "loginuser.php";
  const onLoginStart = useCallback(() => {
    alert("login start");
  }, []);

  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  const onLogoutSuccess = useCallback(() => {
    /*     setProfile(null);
    setProvider(""); */
    alert("logout success");
  }, []);

  const onLogout = useCallback(() => {}, []);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((values) => ({ ...values, [name]: value }));
  };
  console.log("table: ", tbname);
  const handleOnSubmit = (data) => {
    axios.post(`${buyPackageUrl}${tbname}`, data).then(function (response) {
      console.log(response.data);
      if (response.data.status === 1) {
        window.localStorage.setItem("id", response.data.user.id);
        window.localStorage.setItem(
          "distributeID",
          response.data.user.distributeID
        );
        window.localStorage.setItem("email", response.data.user.email);
        window.localStorage.setItem(
          "fullname",
          response.data.user.firstname + " " + response.data.user.lastname
        );
        window.localStorage.setItem("owner", owner);
        if (owner == "Provider") {
          navigate(`/orderpackage/${id}/${response.data.user.id}/${owner}`);
        } else {
          //orderuserpackage
          navigate(`/orderuserpackage/${id}/${response.data.user.id}/${owner}`);
        }

        // navigate(`/orderpackage/${id}/${response.data.user.id}/${owner}`);
        /*         if (response.data.user.id != "") {
          setTimeout(
            () => navigate(`/orderpackage/${id}/${response.data.user.id}`),
            1000
          );
        } */
        // navigate(`/orderpackage/${id}/${response.data.user.id}`);
        /*         } else {
          navigate("/");
        } */
        // navigate("/");
        // navigate("/");
      }
    });
  };
  function readEmail(email) {
    axios.get(`${buyPackageUrl}login.php/${email}`).then(function (response) {
      console.log("Read Email: ", response.data);
      setSocialData(response.data.user);
      if (response.data.user.id !== null) {
        window.localStorage.setItem("id", response.data.user.id);
        window.localStorage.setItem(
          "distributeID",
          response.data.user.distributeID
        );
        window.localStorage.setItem("email", response.data.user.email);
        window.localStorage.setItem(
          "fullname",
          response.data.user.firstname + " " + response.data.user.lastname
        );
        window.localStorage.setItem("owner", owner);
        navigate(`/orderpackage/${id}/${response.data.user.id}/${owner}`);
      }
    });
  }
  const loginSocial = (email) => {};
  console.log("PP", profile);
  // console.log("Email: ", profile.email);
  // console.log("PV", provider);

  useEffect(() => {
    if (profile !== null) {
      readEmail(profile?.email);
      console.log("Socail Data: ", socialData);
      if (socialData !== null) {
        console.log("Login Ok");
      }
    } else {
      console.log("No Profile");
    }
    /*     if (profileFb !== null) {
      readEmail(profileFb?.email);
      console.log("Socail Data: ", socialData);
      if (socialData !== null) {
        console.log("Login Ok");
      }
    } else {
      console.log("No Profile");
    } */
  }, [profile]);

  return (
    <div className="section container mx-auto mt-6 mb-8">
      <div className="flex flex-col gap-4 md:flex-row ">
        <div className="flex-1  border px-4 py-8">
          <div className="mb-8">
            <LoginSocialGoogle
              client_id={
                "18394294506-luj6vo6p27l0fs675csatq2h5pu5boec.apps.googleusercontent.com"
              }
              onLoginStart={onLoginStart}
              // redirect_uri={REDIRECT_URI}
              scope="openid profile email"
              discoveryDocs="claims_supported"
              // access_type="offline"
              onResolve={({ provider, data }) => {
                // console.log("My email: ", data.email);
                setProvider(provider);
                setProfile(data);
                // readEmail(data.email);
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <GoogleLoginButton />
            </LoginSocialGoogle>
          </div>
          {/*           <div className="mb-8">
            <LoginSocialFacebook
              appId="1692785154563581"
              onResolve={(response) => {
                console.log(response);
                console.log(response.data);
                console.log("FB email", response.data.email);
                setProfileFb(response.data);
              }}
              onReject={(error) => {
                console.log(error);
              }}
            >
              <FacebookLoginButton />
            </LoginSocialFacebook>
          </div> */}
          <div className="border"></div>
          <form onSubmit={handleSubmit(handleOnSubmit)} className="mt-6">
            <div className="mb-2">
              <label className="block text-sm font-semibold text-gray-800">
                E-mail
              </label>
              <input
                type="email"
                className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                {...register("email", {
                  required: true,
                })}
              />
              {errors.email && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter Email
                </div>
              )}
            </div>
            <div className="mb-2">
              <label className="block text-sm font-semibold text-gray-800">
                Password
              </label>
              <input
                type="password"
                className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                {...register("password", {
                  required: true,
                })}
              />
              {errors.password && (
                <div className="my-3 text-normal text-red-500 text-xs italic">
                  Enter Password
                </div>
              )}
            </div>
            <Link to="/" className="text-xs text-purple-600 hover:underline">
              Forgot Password?
            </Link>
            <div className="mt-6">
              <button className="w-full px-4 py-4 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">
                Signin with Email {owner}
              </button>
            </div>
          </form>
        </div>
        <div className="flex-1"></div>
      </div>
    </div>
  );
};

export default SignIn;
