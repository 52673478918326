import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

import {
  officesPopularsHeaderUrl,
  officesPopularsUrl,
  imgOfficeUrl,
  mPackageUrl,
  imgPackageUrl,
  buyPackageUrl,
} from "../utils/baseUrl";
import { ShopCard, TopHeader } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import Moment from "react-moment";

const SavePayment = () => {
  const { orderID, methodID } = useParams();
  return (
    <>
      <TopHeader text="Payment" subtext="Giftlikes Provider" />
      <section className="section">
        <div className="container mx-auto">
          {orderID} {methodID}
        </div>
        {/*     <div className="container mx-auto">
  {orderData.map((item, i) => (
    <div
      key={i}
      className="flex justify-between items-center py-4 px-2 border"
    >
      <div className="flex gap-4 ">
        <div>
          <span className="font-bold">
            {item.id}#
            <Moment format="DD/MM/YYYY" date={item.orderDate} />
          </span>
          <br />
          <span className="font-bold">Name:</span> {item.packageName}
          <br />
          <span className="font-bold">Price:</span> {item.packagePrice}
        </div>
      </div>
      <div>
        <Link
          to={`/paymentmethod/${item.id}`}
          className="mr-1 bg-lime-500 hover:bg-lime-700 text-white text-center py-1 px-4 rounded"
        >
          Payment
        </Link>
      </div>
    </div>
  ))}
</div> */}
      </section>
    </>
  );
};

export default SavePayment;
