import React from "react";
import { Header, Footer } from "../components";
const MainLayout = ({ children }) => {
  return (
    <div className="max-w-[1920px] bg-white font-Notos mx-auto relative overflow-hidden">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default MainLayout;
