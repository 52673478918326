import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  settingsTypeUrl,
  appinfoUrl,
  mainOccUrl,
  locationUrl,
  imgmainOccUrl,
  officesFaqUrl,
  imgFaqUrl,
} from "../utils/baseUrl";

import toast, { Toaster } from "react-hot-toast";
/* import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; */
import axios from "axios";

/* import Card from "./../../Card";

import Modal from "react-modal";

import Swal from "sweetalert2"; */

import { IoTrashOutline, IoCloseSharp } from "react-icons/io5";
import {
  HiOutlineBanknotes,
  HiMiniPencilSquare,
  HiMiniPlus,
} from "react-icons/hi2";

// import { IconName } from "react-icons/fa";

import { FiSave } from "react-icons/fi";

//import QuestionMarkIcn from '../src/assets/img/faq/icons/question-mark.svg';

import QuestionMarkIcn from "../assets/img/faq/icons/question-mark.svg";
import Accordion from "./Accordion";
const Faq = () => {
  //officesFaqUrl,imgFaqUrl
  const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState([]);

  function readAppData() {
    axios.get(`${officesFaqUrl}read.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }

  useEffect(() => {
    readAppData();
  }, []);
  console.log(dataApp);
  // pt-[480px] lg:pt-[280px] mb-[80px] lg:mb-0section lg:h-[1160px] lg:pt-6
  return (
    <section className="pb-[60px] xl:pb-[120px] overflow-hidden">
      <div className="max-w-[768px] mx-auto lg:bg-faq bg-no-repeat bg-custom bg-center lg:h-full lg:pt-6">
        <div className="">
          <img className="" src={QuestionMarkIcn} alt="" />
          <h2 className="h2 text-center">
            FAQ <span className="text-primary-200">.</span>
          </h2>
        </div>
        {/* accordion list */}
        <div className="flex flex-col gap-y-4 px-4 w-full overflow-y-scroll">
          {dataApp.map((accordion, idx) => {
            return <Accordion accordion={accordion} key={idx} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Faq;
