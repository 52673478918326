import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

import {
  officesPopularsHeaderUrl,
  officesPopularsUrl,
  imgOfficeUrl,
  mPackageUrl,
  imgPackageUrl,
  buyPackageUrl,
} from "../utils/baseUrl";
import { ShopCard, TopHeader } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import Moment from "react-moment";
import Wallet from "../assets/wallet_color.png";

const PaymentUserMethod = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [userId, setUserId] = useState("");
  const [dataApp, setDataApp] = useState([]);
  const [owner, setOwner] = useState("");

  function readAppData() {
    axios
      .get(`${buyPackageUrl}readpaymentmethod.php`)
      .then(function (response) {
        console.log(response.data);
        setDataApp(response.data);
      });
  }

  useEffect(() => {
    const id = localStorage.getItem("id");
    const owuser = localStorage.getItem("owner");
    //owner
    console.log("user: ", owner);
    setUserId(id);
    setOwner(owuser);
    readAppData();
  }, []);

  const endPayment = (methodID) => {
    console.log("End: ", id, methodID);
    //savepayment
    //savebankpayment
    //savewalletpayment
    if (methodID == 1) {
      console.log("Bank");
      navigate(`/saveuserbankpayment/${id}/${methodID}`);
    } else {
      console.log("Wallet");
      navigate(`/savewalletpayment/${id}/${methodID}`);
    }
    // navigate(`/savepayment/${orderID}/${id}`);
  };

  console.log("Pay Method: ", dataApp);
  return (
    <>
      <TopHeader text="Payment User Method" subtext="Giftlikes Provider" />
      <section className="section">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
            {dataApp.map((item, i) => (
              <div className="border p-8" key={i}>
                <div className="flex flex-col justify-center items-center">
                  <div>
                    <h2 className="font-bold text-3xl">{item.title}</h2>
                    <h3>{item.enName}</h3>
                  </div>
                  <div>
                    <img
                      className="w-[150px] h-[150px] object-cover"
                      src={Wallet}
                      alt=""
                    />
                  </div>
                  <div>
                    <button
                      onClick={() => endPayment(item.id)}
                      // to={`/paymentmethod/${item.id}`}
                      className="mr-1 bg-lime-500 hover:bg-lime-700 text-white text-center py-1 px-4 rounded"
                    >
                      Payment
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* 
    payment_method id,title,enName,uriLink,created_at
    <div className="container mx-auto">
  {orderData.map((item, i) => (
    <div
      key={i}
      className="flex justify-between items-center py-4 px-2 border"
    >
      <div className="flex gap-4 ">
        <div>
          <span className="font-bold">
            {item.id}#
            <Moment format="DD/MM/YYYY" date={item.orderDate} />
          </span>
          <br />
          <span className="font-bold">Name:</span> {item.packageName}
          <br />
          <span className="font-bold">Price:</span> {item.packagePrice}
        </div>
      </div>
      <div>
        <Link
          to={`/paymentmethod/${item.id}`}
          className="mr-1 bg-lime-500 hover:bg-lime-700 text-white text-center py-1 px-4 rounded"
        >
          Payment
        </Link>
      </div>
    </div>
  ))}
</div> */}
      </section>
    </>
  );
};

export default PaymentUserMethod;
