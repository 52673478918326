import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

import {
  officesPopularsHeaderUrl,
  officesPopularsUrl,
  imgOfficeUrl,
  mPackageUrl,
  imgPackageUrl,
  buyPackageUrl,
} from "../utils/baseUrl";
import { ShopCard, TopHeader } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
const OrderPackage = () => {
  const { id, who } = useParams();
  const [packageData, setPackageData] = useState({});
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [distributeId, setDistributeId] = useState("");
  const [shopData, setShopData] = useState([]);
  function readPackage(id) {
    axios
      .get(`${buyPackageUrl}readpackagebyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setPackageData(response.data);
      });
  }
  function readShop(id) {
    axios.get(`${buyPackageUrl}readshop.php/${id}`).then(function (response) {
      console.log("Shop Company: ", response.data);
      setShopData(response.data);
    });
  }

  useEffect(() => {
    var user = localStorage.getItem("id");
    var distribite = localStorage.getItem("distributeID");
    setUserId(user);
    setDistributeId(distribite);
    readPackage(id);
    readShop(user);
  }, [userId, id]);

  /*   useEffect(
    useCallback(() => { */
  /*       var user = localStorage.getItem("id");
      var distribite = localStorage.getItem("distributeID");
      setUserId(user);
      setDistributeId(distribite);
      readPackage(id);
      readShop(user); */
  /*       setAuth(user);
      setUserId(id);
      readAppData(); */
  /*       return () => {};
    }, [])
  ); */

  const savePackage = () => {
    setLoading(true);
    /*     $memberID=$data->memberID;
    $partnerID=$data->partnerID;
    $distributeID=$data->distributeID;
    $orderDate=date("d-m-Y; h:i:s");
    $packageId=$data->packageId;
    $packageName=$data->packageName;
    $packagePrice=$data->packagePrice;
    $packageTypeID=$data->packageTypeID;
    $packageForID=$data->packageForID; */
    axios
      .post(`${buyPackageUrl}savepackage.php`, {
        memberID: userId,
        packageId: id,
      })
      .then(function (response) {
        console.log(response.data);
        // toast.success("Successfully created!");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  //readpackagebyid.php
  return (
    <>
      <TopHeader text="Order Package" subtext="Giftlikes Provider" />

      <section className="section">
        <div className="container w-full mx-auto">
          {/* <h2>Order Package-{userId}</h2> */}
          <ShopCard
            companydata={shopData}
            pkId={id}
            memberID={userId}
            distributeID={distributeId}
          />
        </div>

        {/*       <div className="w-[400px] mx-auto">
        <div
          className="flex flex-col justify-center shadow rounded-lg mb-16
         p-8"
        >
          <h3 className="font-semibold text-xl mb-4">{packageData?.title}</h3>
          {packageData?.photo !== "" ? (
            <img
              src={`${imgPackageUrl}${packageData?.photo}`}
              className="rounded-lg object-cover mx-auto"
            />
          ) : null}

          <p className="text-base mt-4">{packageData?.shortdetail}</p>
          <p className="text-gray-600 text-sm leading-normal my-2">
            {packageData?.longdetail}
          </p>
          <p className="text-xl mb-4">
            <span className="text-rose-600 ">
              {Number(packageData?.price).toFixed(0)}฿
            </span>
            /{packageData?.period}-
            <span className="text-base">{packageData?.unit}</span>
          </p>
          <button
            className="bg-sky-500 text-white rounded-xl shadow-sm
  font-semibold md:text-sm text-xs tracking-wide md:px-9 px-7 py-4"
            onClick={savePackage}
          >
            Confirm Buy Package
          </button>
        </div>
      </div> */}
      </section>
    </>
  );
};

export default OrderPackage;
