import React from "react";

const Package = () => {
  return (
    <section className="section h-screen">
      <h2 className="text-white">About</h2>
    </section>
  );
};

export default Package;
