import React, { useState, useEffect } from "react";
import {
  officesPopularsHeaderUrl,
  officesPopularsUrl,
  imgOfficeUrl,
} from "../utils/baseUrl";
import axios from "axios";

/* import ButtonFill from "./ButtonFill";
import Icon1 from "../assets/img/icon-1.png";
import Icon2 from "../assets/img/icon-2.png";
import Icon3 from "../assets/img/icon-3.png"; */
const Feature = () => {
  /*   const Info = [
    {
      icon: Icon1,
      title: "Secret Locations",
      des: "Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor.",
    },
    {
      icon: Icon2,
      title: "Safe Adventure",
      des: "Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor.",
    },
    {
      icon: Icon3,
      title: "Professional Hikers",
      des: "Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor.",
    },
  ]; */
  // const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState(null);
  const [featuresApp, setFeaturesApp] = useState([]);
  function readAppData(flag) {
    axios
      .get(`${officesPopularsHeaderUrl}readheaderbyflag.php/${flag}`)
      .then(function (response) {
        console.log(response.data);
        setDataApp(response.data);
      });
  }

  function readFAppData() {
    axios.get(`${officesPopularsUrl}readlimit.php`).then(function (response) {
      console.log(response.data);
      setFeaturesApp(response.data);
    });
  }
  useEffect(() => {
    readAppData("FH");
    readFAppData();
  }, []);
  console.log("FH", dataApp);
  return (
    <section className="mt-[60px] section bg-[#081730]">
      <div className="text-center">
        <div className="flex justify-center mb-4">
          {dataApp?.photo !== null ? (
            <img
              src={`${imgOfficeUrl}${dataApp?.photo}`}
              className="rounded-lg w-[100px]"
            />
          ) : null}
        </div>
        <p className="text-primary text-white text-sm mb-4">{dataApp?.title}</p>
        <h1 className="md:text-4xl text-2xl text-white font-semibold">
          {dataApp?.subtitle}
        </h1>
        {/* <p className="text-primary text-white text-sm">{dataApp.photo}</p> */}
      </div>
      <div className="bg-white shadow-2xl mx-[20px] flex md:flex-row flex-col gap-10 md:p-14 p-10 mt-5 rounded-md">
        {featuresApp.map((info, i) => (
          <div key={i}>
            {/* <img src={info.icon} alt="icon" className="h-16" />
             */}
            <div className="mb-4 flex">
              {info.photo !== "" ? (
                <img src={`${imgOfficeUrl}${info.photo}`} className="h-16" />
              ) : null}
            </div>

            <h1 className="font-semibold text-lg my-3">{info.title}</h1>
            <p className="description">{info.description}</p>
            {/*             <button className="text-rose-600 font-medium text-sm my-1">
              Read More
            </button> */}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Feature;
