import React, { useState, useEffect } from "react";
import { officesPTUrl, imgOfficeUrl } from "../utils/baseUrl";
import axios from "axios";
import { BsDownload } from "react-icons/bs";
const Privacy = () => {
  // const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState([]);

  function readAppData() {
    axios.get(`${officesPTUrl}readPlimit.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }

  useEffect(() => {
    readAppData();
  }, []);

  console.log("Policy: ", dataApp);

  return (
    <section className="section">
      {/* <h2 className="">Policy</h2> */}
      <div className="container mx-auto w-full">
        <div className="text-sm flex justify-end">
          <a
            href={`${imgOfficeUrl}${dataApp?.urlfiledownload}`}
            target="_blank"
            className="bg-blue-500 hover:bg-blue-700 text-white border rounded w-[150px] py-1 pl-2 flex gap-2 items-center"
          >
            <BsDownload size={22} /> Download pdf
          </a>
        </div>
        <div className="py-4">
          <p
            className="mb-4 text-lg"
            dangerouslySetInnerHTML={{
              __html: dataApp?.content,
            }}
          ></p>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
