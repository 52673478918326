import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

import {
  officesPopularsHeaderUrl,
  officesPopularsUrl,
  imgOfficeUrl,
  mPackageUrl,
  imgPackageUrl,
  buyPackageUrl,
} from "../utils/baseUrl";
import { ShopCard, TopHeader } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import Moment from "react-moment";

const OrderHistory = () => {
  // const { id } = useParams();
  const [packageData, setPackageData] = useState({});
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [distributeId, setDistributeId] = useState("");
  const [shopData, setShopData] = useState([]);
  const [orderData, setOrderData] = useState([]);

  function readPackage(id) {
    axios
      .get(`${buyPackageUrl}readpackagebyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setPackageData(response.data);
      });
  }

  function readOrder(id) {
    axios
      .get(`${buyPackageUrl}readhistory.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setOrderData(response.data);
      });
  }

  function readShop(id) {
    axios.get(`${buyPackageUrl}readshop.php/${id}`).then(function (response) {
      console.log("Shop Company: ", response.data);
      setShopData(response.data);
    });
  }

  useEffect(() => {
    var user = localStorage.getItem("id");
    var distribite = localStorage.getItem("distributeID");
    setUserId(user);
    setDistributeId(distribite);
    // readPackage(id);
    readOrder(user);
    // readShop(user);
  }, []);

  const canclePayment = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${buyPackageUrl}canclepayment.php/${id}`)
          .then(function (response) {
            console.log(response.data);
            readOrder(userId);
            // readTypeData();
            // Swal.fire("Deleted!", "Your file has been deleted.", "success");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  console.log("Order: ", orderData);
  return (
    <>
      <TopHeader text="Order History" subtext="Giftlikes Provider" />
      <section className="section">
        <div className="container mx-auto">
          {orderData.map((item, i) => (
            <div
              key={i}
              className="flex justify-between items-center py-4 px-2 border"
            >
              <div className="flex gap-4 ">
                {/*                 <h2>
                  {item.id}#<Moment format="DD/MM/YYYY" date={item.orderDate} />
                </h2> */}
                {/* <h3>{item.packageId}</h3> */}
                <div>
                  <span className="font-bold">
                    {item.id}#
                    <Moment format="DD/MM/YYYY" date={item.orderDate} />
                  </span>
                  <br />
                  <span className="font-bold">Name:</span> {item.packageName}
                  <br />
                  <span className="font-bold">Price:</span> {item.packagePrice}
                  <br />
                  <span className="font-bold">Status:</span>{" "}
                  {item.paymentStatus}
                  {item.title}
                </div>
              </div>
              <div>
                {item.paymentStatus <= 1 ? (
                  <>
                    <Link
                      to={`/paymentmethod/${item.id}`}
                      className="mr-1 bg-lime-500 hover:bg-lime-700 text-white text-center py-1 px-4 rounded"
                    >
                      Payment
                    </Link>
                    <button
                      onClick={() => canclePayment(item.id)}
                      // to={`/paymentmethod/${item.id}`}
                      className="mr-1 bg-red-500 hover:bg-red-700 text-white text-center py-1 px-4 rounded"
                    >
                      Cancle
                    </button>
                  </>
                ) : (
                  <>
                    <div className="mr-1 bg-slate-500 hover:bg-slate-700 text-white text-center py-1 px-4 rounded">
                      Payment
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default OrderHistory;
