import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "./navbar/Nav";
import ButtonFill from "./ButtonFill";
import ButtonOutline from "./ButtonOutline";
import HeroImg from "../assets/img/p1.png";
import DownloadAds from "./DownloadAds";
import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  imglocationUrl,
  officesHerosUrl,
  imgOfficeUrl,
} from "../utils/baseUrl";

import axios from "axios";

import appStore from "../assets/icons/buttons/app-store.svg";
import googlPlay from "../assets/icons/buttons/google-play.svg";

const TopHero = () => {
  //bg-Hero bg-cover bg-center bg-green-300
  const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState([]);

  function readAppData() {
    axios.get(`${officesHerosUrl}readlimit.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }

  useEffect(() => {
    readAppData(); //bg-[#b2b7c2]/10h-full
  }, []);
  return (
    <div className="flex flex-col xl:flex-row gap-y-8 justify-center items-center xl:justify-start w-full pt-[60px] xl:pt-[120px]  h-full">
      {/* text  */}
      <div className="w-full flex-1 text-center xl:max-w-xl xl:text-left mt-2 xl:mt-0">
        <h1 className="h1 font-Montserrat font-semibold">
          {dataApp?.title}
          <br />
          <span>{dataApp?.subtitle}</span>
        </h1>
        <p className="description max-w-[550px] mx-auto xl:mx-0 mb-6 xl:mb-10">
          {dataApp?.description}
        </p>
        {/* btns  */}
        <div className="flex gap-x-3 justify-center xl:justify-start">
          <button className="btn-cta">
            <img src={googlPlay} className="w-[132px] h-[36px]" alt="" />
          </button>
          <button className="btn-cta">
            <img src={appStore} className="w-[132px] h-[36px]" alt="" />
          </button>
        </div>
      </div>
      {/* image  w-full my-4 xl:my-0*/}
      <div className="flex-1 w-full xl:ml-2 overflow-hidden rounded-xl">
        <img
          // src={HeroImg}w-full h-[350px] object-scale-down xl:h-[350px]w-[100%] h-[100%] object-contain  rounded opacity-90
          src={`${imgOfficeUrl}${dataApp.photo}`}
          alt={dataApp?.title}
          className="w-full place-self-end object-conver  rounded-xl opacity-90"
        />
      </div>
    </div>
  );
};

export default TopHero;
