import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import MainLayout from "./template/MainLayout";
import {
  Dashboard,
  About,
  Contact,
  Privacy,
  Term,
  Package,
  OrderPackage,
  SavePackage,
  SignIn,
  OrderHistory,
  PaymentMethod,
  SavePayment,
  SaveBankTransfer,
  SaveWallet,
  OrderUserPackage,
  OrderUserHistory,
  PaymentUserMethod,
  SaveUserBankTransfer,
} from "./pages";
const Layout = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/privacypolicy",
        element: <Privacy />,
      },
      {
        path: "/termofservice",
        element: <Term />,
      },
      {
        path: "/buypackage",
        element: <Package />,
      },
      {
        path: "/orderpackage/:id/:userID/:who",
        element: <OrderPackage />,
      },
      {
        path: "/orderuserpackage/:id/:userID/:who",
        element: <OrderUserPackage />,
      },
      {
        path: "/savepackage",
        element: <SavePackage />,
      },
      {
        path: "/orderhistory",
        element: <OrderHistory />,
      },
      {
        path: "/orderuserhistory",
        element: <OrderUserHistory />,
      },
      {
        path: "/paymentmethod/:id",
        element: <PaymentMethod />,
      },
      {
        path: "/paymentusermethod/:id",
        element: <PaymentUserMethod />,
      },
      {
        path: "/savepayment/:orderID/:methodID",
        element: <SavePayment />,
      },
      {
        path: "/savebankpayment/:orderID/:methodID",
        element: <SaveBankTransfer />,
      },
      {
        path: "/saveuserbankpayment/:orderID/:methodID",
        element: <SaveUserBankTransfer />,
      },
      {
        path: "/savewalletpayment/:orderID/:methodID",
        element: <SaveWallet />,
      },
      {
        path: "/signin/:id/:owner",
        element: <SignIn />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
