import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "./navbar/Nav";
import ButtonFill from "./ButtonFill";
import ButtonOutline from "./ButtonOutline";
import HeroImg from "../assets/img/p1.png";
import DownloadAds from "./DownloadAds";
import {
  settingsTypeUrl,
  appinfoUrl,
  locationUrl,
  imglocationUrl,
  officesHerosUrl,
  imgOfficeUrl,
} from "../utils/baseUrl";

import axios from "axios";

import appStore from "../assets/icons/buttons/app-store.svg";
import googlPlay from "../assets/icons/buttons/google-play.svg";

const Header = () => {
  //bg-Hero bg-cover bg-center bg-green-300
  const [loading, setLoading] = useState(false);
  const [dataApp, setDataApp] = useState([]);

  function readAppData() {
    axios.get(`${officesHerosUrl}readlimit.php`).then(function (response) {
      console.log(response.data);
      setDataApp(response.data);
    });
  }

  useEffect(() => {
    readAppData(); //bg-[#b2b7c2]/10h-full
  }, []);
  return (
    <section className="xl:pt-10">
      <div className="container mx-auto">
        <Nav />
      </div>
    </section>
  );
};

export default Header;
