import React from "react";
import ModelBlackImg from "../assets/model-black.png";

const TopHeader = ({ text = "", subtext = "" }) => {
  return (
    <section className="min-h-[300px] bg-pink-200/20  w-full py-4 mt-4 lg:mt-8">
      <div className="pt-[100px] text-center capitalize flex flex-col items-center">
        <h2 className="h1 drop-shadow-md hover:drop-shadow-xl">{text}</h2>
        <p className="h3 my-4 drop-shadow-md hover:drop-shadow-xl">{subtext}</p>
      </div>
      <div className="flex justify-center mb-8">
        <img src={ModelBlackImg} alt="" />
      </div>
    </section>
  );
};

export default TopHeader;
