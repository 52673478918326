import React, { useState, useEffect, useCallback } from "react";
import { buyPackageUrl, officesMenusUrl } from "../../utils/baseUrl";
import axios from "axios";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
const NavLinks = ({ open, setOpen = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [Links, setLinks] = useState([]);
  const location = useLocation();
  const [auth, setAuth] = useState(null);
  const [userId, setUserId] = useState("");
  const [owner, setOwner] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const id = localStorage.getItem("id");
    const user = localStorage.getItem("fullname");
    const owuser = localStorage.getItem("owner");
    //owner
    console.log("user: ", user);
    setAuth(user);
    setUserId(id);
    setOwner(owuser);
    readAppData();
    /*     if (user === null) {
      navigate("/login");
    } */
    /*     setTimeout(() => {
      navigate('/about', { replace: true });
    }, 3000); */
  }, []);

  const LogOut = () => {
    // setIsClicked(initialState);
    localStorage.removeItem("id");
    localStorage.removeItem("fullname");
    localStorage.removeItem("email");
    localStorage.removeItem("distributeID");
    localStorage.removeItem("owner");
    /*localStorage.removeItem("incomeperc"); */
    localStorage.clear();
    navigate("/");
    // setTimeout(() => navigate("/"), 1000);
  };

  function readAppData() {
    axios.get(`${buyPackageUrl}readmenulimit.php`).then(function (response) {
      console.log(response.data);
      setLinks(response.data);
    });
  }

  /*   useEffect(
    useCallback(() => {
      readAppData();
      return () => {};
    }, [])
  ); */

  /*   useEffect(
    useCallback(() => {
      const id = localStorage.getItem("id");
      const user = localStorage.getItem("fullname");
      const owuser = localStorage.getItem("owner");
      console.log("user: ", user);
      setAuth(user);
      setUserId(id);
      setOwner(owuser);
      readAppData();
      return () => {};
    }, [])
  ); */

  /*   useEffect(() => {
    var id = localStorage.getItem("id");
    var user = localStorage.getItem("fullname");
    console.log("user: ", user);
    setAuth(user);
    setUserId(id);
    readAppData();
  }, [userId, navigate]); */

  // console.log(Links);

  //Term of service /termofservice,Privacy policy /privacypolicy
  /*   const Links = [
    {
      name: "Location",
      link: "/",
    },
    {
      name: "Blogs",
      link: "/",
    },
    {
      name: "Testimoials",
      link: "/",
    },
    {
      name: "Contact",
      link: "/",
    },
  ]; */
  // console.log("Links: ", Links);
  //Object.keys(Links).length != 0 && Links.constructor === Object ? (
  return (
    <>
      {Links.length > 0 &&
        Links.map((link, i) => (
          <li key={i} className="font-semibold hover:text-primary">
            <NavLink to={link.url} onClick={() => setOpen(!open)}>
              {link.title}
            </NavLink>
          </li>
        ))}
      {userId !== null ? (
        <>
          {owner === "Provider" ? (
            <NavLink
              className={"font-bold"}
              to={"/orderhistory"}
              onClick={() => setOpen(!open)}
            >
              Orders
            </NavLink>
          ) : (
            <NavLink
              className={"font-bold"}
              to={"/orderuserhistory"}
              onClick={() => setOpen(!open)}
            >
              User Orders
            </NavLink>
          )}

          <button className="btn-outline px-4 py-2" to={"/"} onClick={LogOut}>
            Logout
          </button>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default NavLinks;
