import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

import {
  officesPopularsHeaderUrl,
  officesPopularsUrl,
  imgOfficeUrl,
  mPackageUrl,
  imgPackageUrl,
  buyPackageUrl,
} from "../utils/baseUrl";
import { ShopCard, TopHeader } from "../components";
import axios from "axios";
import Swal from "sweetalert2";

const OrderUserPackage = () => {
  const navigate = useNavigate();
  const { id, userID, who } = useParams();
  const [loading, setLoading] = useState(false);
  const [packageData, setPackageData] = useState({});
  const [authId, setAuthId] = useState("");
  const [owner, setOwner] = useState("");
  const [dataApp, setDataApp] = useState(null);
  //   const [packageData, setPackageData] = useState([]);
  function readPackage(id) {
    axios
      .get(`${buyPackageUrl}readpackagebyid.php/${id}`)
      .then(function (response) {
        console.log(response.data);
        setPackageData(response.data);
      });
  }

  useEffect(() => {
    const id = localStorage.getItem("id");
    const owuser = localStorage.getItem("owner");
    //owner
    console.log("user: ", owner);
    setAuthId(id);
    setOwner(owuser);
    readPackage(id);
    // readPackage(owner);
  }, []);

  /*   useEffect(
    useCallback(() => {
      const id = localStorage.getItem("id");
      const owuser = localStorage.getItem("owner");
      console.log("user: ", owner);
      setAuthId(id);
      setOwner(owuser);
      readPackage(id);
      return () => {};
    }, [])
  ); */
  const onSaveOrder = (pkID) => {
    setLoading(true);
    axios
      .post(`${buyPackageUrl}saveuserpackage.php`, {
        userID: userID,
        packageId: pkID,
      })
      .then(function (response) {
        console.log(response.data);
        // toast.success("Successfully created!");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        // notifySuccess("บันทึกข้อมูลเรียบร้อยแล้ว");
        setLoading(false); // Stop loading
      });
  };
  return (
    <section className="section text-center pb-[4rem]">
      <div className="container mx-auto">
        <div className="mb-4 flex justify-center">
          <div
            className="flex flex-col justify-center shadow rounded-lg mb-16
         p-8"
          >
            <h3 className="font-semibold text-xl mb-4">{packageData?.title}</h3>
            {/* <img src={avatar} alt="avatar" className="w-20 mx-auto" /> */}
            {packageData?.photo !== "" ? (
              <img
                src={`${imgPackageUrl}${packageData?.photo}`}
                className="rounded-lg h-[150px] object-cover mx-auto"
              />
            ) : null}

            <p className="text-base mt-4">{packageData?.shortdetail}</p>
            <p className="text-gray-600 text-sm leading-normal my-2">
              {packageData?.longdetail}
            </p>
            <p className="text-xl mb-4">
              <span className="text-rose-600 ">
                {Number(packageData?.price).toFixed(0)}฿
              </span>{" "}
              /{packageData?.period}-
              <span className="text-base">{packageData?.unit}</span>
            </p>
            {/* <ButtonFill>Buy Package</ButtonFill> */}
            <button
              className="bg-sky-500 text-white rounded-xl shadow-sm
  font-semibold md:text-sm text-xs tracking-wide md:px-9 px-7 py-4"
              onClick={() => onSaveOrder(packageData?.id)}
            >
              Buy Package
            </button>
          </div>
        </div>
        ;
      </div>
    </section>
  );
};

export default OrderUserPackage;
