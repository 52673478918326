import React from "react";

const DownloadAds = () => {
  const downloadImgStyle =
    "border-[2px] border-[#232A4E] rounded-[5px] w-[132px] py-2 px-2 object-conver"; //border-[2px] border-[#232A4E]
  return (
    <div className="flex gap-x-2 md:gap-2">
      <img
        src={require("../assets/img/AppStore.png")}
        alt=""
        className={downloadImgStyle}
      />
      <img
        src={require("../assets/img/GooglePlay.png")}
        alt=""
        className={downloadImgStyle}
      />
    </div>
  );
};

export default DownloadAds;
